import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./layout.scss";
import { Sidebars } from "../pages/sidebar/Sidebars";
import panelLogo from "../assets/images/logo.png";
import avatar from "../assets/images/logo.png";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const PanelLayout = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("otidLang"));
  const currentUser = useSelector(state => state.userData?.value)
  const { i18n } = useTranslation();
  const sideBarMenu = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleLanuageChange = (e) => {
    setSelectedLanguage(e);
    localStorage.setItem("otidLang", e);
    i18n.changeLanguage(e);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="panel-wrapper flex flex-wrap ">
        {/* <OutsideClickHandler
          onOutsideClick={() => {
            setOpenSidebar(false);
          }}
        /> */}
        <div className={`panel-sidebar ${openSidebar ? "hide" : "show"}`}>
          <figure>
            <Link to="/">
              <img src={panelLogo} alt={panelLogo} />
            </Link>
          </figure>
          <Sidebars sideBarHandler={sideBarMenu} />
        </div>
        {/* </OutsideClickHandler> */}
        <div
          className={`panel-main-content flex column-direction ${openSidebar ? "hide" : "show"
            }`}
        >
          <header className="panel-header items-center flex content-justify-between">
            <div className="left-side">
              <button
                onClick={sideBarMenu}
                className={`side-menu-btn border-0 ${openSidebar ? "hide" : "show"
                  }`}
                style={{ fontSize: "25px" }}
              >
                {openSidebar === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 24 24"
                    style={{ fill: "rgba(0, 0, 0, 1)" }}
                  >
                    <path d="M4 6h16v2H4zm4 5h12v2H8zm5 5h7v2h-7z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 24 24"
                    style={{ fill: "rgba(0, 0, 0, 1)" }}
                  >
                    <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
                  </svg>
                )}
              </button>
            </div>
            {/* <div className="right-side">
              <div className="select-wrapper mx-3">
                <select
                  className="lang mx-3 px-3 bg-light form-control"
                  value={selectedLanguage}
                  onChange={(e) => handleLanuageChange(e.target.value)}
                >
                  <option value="en">English</option>
                  <option value="sv">Swedish</option>
                </select>
              </div>
              <figure className="avatar_wrapper flex items-center">
                <img src={avatar} alt="avatar" />
                <figcaption className="flex column-direction">
                  <strong>{`${currentUser?.first_name} ${currentUser?.last_name}`}</strong>
                </figcaption>
              </figure>
            </div> */}
          </header>
          <div className="panel-main-wrapper">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
