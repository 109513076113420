import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { createInvoice } from "../../redux/actions/invoiceActions";
import { invoiceData } from "../../redux/slices/timereportSlice";
import "./AddEditWorkOrder.scss";

import {
  currentTab,
  selectedWorkorder,
} from "../../redux/slices/workorderSlice";
import {
  updateWorkorder,
  createWorkorderAttributes,
  updateWorkorderAttributes,
  deleteWorkorderAttributes,
} from "../../redux/actions/workorderActions";
import { customerList } from "../../redux/actions/customersActions";
import { staffList } from "../../redux/actions/staffActions";
import { projectList } from "../../redux/actions/projectActions";
import { selfControlLists } from "../../redux/actions/selfControlListActions";
import TimeReportTab from "./workOrderTabs/TimeReportTab";
import AddEditCustomerComponent from "../customer/AddEditCustomerComponent";
import SummaryTab from "./workOrderTabs/SummaryTab";
import WorkorderInformationTab from "./workOrderTabs/WorkorderInformationTab";
import SelfControlTab from "./workOrderTabs/SelfControlTab";
import DocumentTab from "./workOrderTabs/DocumentTab";
import MaterialTab from "./workOrderTabs/MaterialTab";
import InvoicesTab from "./workOrderTabs/InvoicesTab";
import CustomerWorkOrder from "./workOrderTabs/CustomerWorkOrder";
import { useIsExportInvoiceVisible } from '../CommonDisableFunction';

const AddEditWorkorderComponent = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const offer = state?.offer

  const isExportInvoiceVisible = useIsExportInvoiceVisible();

  // self control list tab state
  const [selectedSelfControlsList, setSelectedSelfControlsList] = useState({});
  const [selectedSelfControlsListPoints, setSelectedSelfControlsListPoints] =
    useState({});
  let [pointCount, setPointCount] = useState(0);

  // Work Order Information
  const [allCustomers, setAllCustomers] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [allStaff, setAllStaff] = useState([]);
  const [timeReportList, setTimeReportList] = useState([]);
  const [selfControls, setSelfControls] = useState([]);

  // utils
  // const [tabKey, setTabKey] = useState("workOrderInfo");
  const [isEdit, setIsEdit] = useState(false);
  const [customerDetail, setCustomerDetail] = useState({});
  

  const selectedWorkorderData = useSelector(
    (state) => state.workorder.selectedWorkorder
  );
  const currentUser = useSelector((state) => state.userData.value);
  const tabKey = useSelector((state) => state.workorder.tab);

  const getDataList = async () => {
    try {
      const [customerResult, projectsResult, selfControlResult, staffResult] =
        await Promise.all([
          customerList(currentUser?.clientId),
          projectList(),
          selfControlLists(),
          staffList(),
        ]);
      setAllCustomers(customerResult?.data || []);
      setAllProjects(projectsResult?.data || []);
      setSelfControls(selfControlResult?.data || []);
      setAllStaff(staffResult?.data || []);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // const handleCreateTimeReport = async (
  //   reportPayload,
  //   remove,
  //   timeReportEdit,
  //   timeReportId
  // ) => {
  //   if (timeReportEdit) {
  //     reportPayload._id = timeReportId;
  //     const data = await updateWorkorderAttributes(
  //       selectedWorkorderData?._id,
  //       reportPayload,
  //       "timeReports"
  //     );
  //     if (data.status) {
  //       setTimeReportList(data?.data.timeReports);
  //       dispatch(selectedWorkorder(data?.data));
  //       toast.success(t("time_report_updated_successfully"));
  //       return { success: true };
  //     }
  //   } else {
  //     let data;
  //     if (remove) {
  //       data = await deleteWorkorderAttributes(
  //         selectedWorkorderData?._id,
  //         reportPayload[0],
  //         "timeReports"
  //       );
  //     } else {
  //       data = await createWorkorderAttributes(
  //         selectedWorkorderData?._id,
  //         reportPayload,
  //         "timeReports"
  //       );
  //     }
  //     if (data.status) {
  //       setTimeReportList(data?.data.timeReports);
  //       dispatch(selectedWorkorder(data?.data));
  //       if (remove) {
  //         return toast.success(t("time_report_deleted_successfully"));
  //       }
  //       toast.success(t("time_report_added_successfully"));
  //       return { success: true };
  //     }
  //   }
  // };

  const handleExportInvoice = async () => {
    if(!selectedWorkorderData?.customer?.customerNumberFortnox || selectedWorkorderData?.customer?.customerNumberFortnox === "0") {
      return toast.info("This customer has not been created in Fortnox.")
     }
    const { material, notesOnWorkorder, customer, aoname, timeReports, _id } =
      selectedWorkorderData;
    const approvedTimereports = timeReports?.filter(
      (t) => t.isApproved && !t.isExported
    );
    const regular = approvedTimereports
      ?.filter((t) => !t.overtime && !t.hoursOnWeekend && !t.cases.length)
      .reduce((acc, item) => {
        return acc + +item.totalHours.slice(0, 2);
      }, 0);
    const overtime1 = approvedTimereports
      ?.filter((t) => t.overtime)
      .reduce((acc, item) => {
        return acc + +item.totalHours.slice(0, 2);
      }, 0);
    const overtime2 = approvedTimereports
      ?.filter((t) => t.hoursOnWeekend)
      .reduce((acc, item) => {
        return acc + +item.totalHours.slice(0, 2);
      }, 0);
    const kmdriven = approvedTimereports
      ?.filter((t) => t.cases?.length)
      .reduce((acc, item) => {
        return acc + +item.totalHours.slice(0, 2);
      }, 0);
    const materialExported = material.filter((m) => !m.isExported);
    const workedHoursData = [];
    regular > 0 &&
      workedHoursData.push({
        name: "worked hours",
        quantity: regular,
        sellingPrice: 400,
      });
    overtime1 > 0 &&
      workedHoursData.push({
        name: "worked hours - overtime1",
        quantity: overtime1,
        sellingPrice: 400,
      });
    overtime2 > 0 &&
      workedHoursData.push({
        name: "worked hours - overtime2",
        quantity: overtime2,
        sellingPrice: 400,
      });
    kmdriven > 0 &&
      workedHoursData.push({
        name: "worked hours - kmdriven",
        quantity: kmdriven,
        sellingPrice: 400,
      });
    const materialwithHours = approvedTimereports?.length
      ? workedHoursData
      : [];
    const materialWithDiscount = [
      ...materialExported,
      ...materialwithHours,
    ]?.map((m) => ({ ...m, discount: customer?.discount }));
    const payload = {
      workorderNotes: notesOnWorkorder,
      customer: customer?.name,
      workorder: _id,
      material: materialWithDiscount,
      timereports: approvedTimereports,
    };
    const createdInvoice = await createInvoice(payload);
    if (createdInvoice?.status) {
      dispatch(invoiceData(createdInvoice?.data?.invoice));
      dispatch(currentTab("Invoices"));
      // const updatedMaterial = material?.map((m) => ({ ...m, isApproved: true }))
      dispatch(selectedWorkorder(createdInvoice?.data.workorder));
      toast.success(createdInvoice?.message);
    }
  };

  useEffect(() => {
    if (tabKey !== "workOrderInfo") {
      setIsEdit(true);
    }
    dispatch(invoiceData({}));
  }, []);

  return (
    <div className="mt-auto mb-0 pb-0 mb-5 pb-4">
      <div className="heading">
        <h3>
          {!isExportInvoiceVisible
            ? t(selectedWorkorderData.status.toLowerCase())
            : t(offer ? "offer" :  "workorder")}
        </h3>
      </div>
      <div className="row mb-0">
        <div className="col-md-12 mx-auto mb-0 work-order-wrapper">
          <div className="card_layout p-0">
            <Tabs
              activeKey={tabKey}
              onSelect={(key) => dispatch(currentTab(key))}
              className="mb-3 mt-3"
            >
              {/* Work Order Information */}
              <Tab
                eventKey="workOrderInfo"
                title={offer ? "Contact information" : t("workorder_info")}
                mountOnEnter={true}
                unmountOnExit={true}
                className="p-4"
              >
                <WorkorderInformationTab
                  selectedSelfControlsList={selectedSelfControlsList}
                  selectedSelfControlsListPoints={
                    selectedSelfControlsListPoints
                  }
                  pointCount={pointCount}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  allCustomers={allCustomers}
                  allProjects={allProjects}
                  workorderData={selectedWorkorderData && selectedWorkorderData}
                  getDataList={getDataList}
                  getCustomerDetail={setCustomerDetail}
                  setAllCustomers={setAllCustomers}
                  allStaff={allStaff}
                  setTimeReportList={setTimeReportList}
                  customerDeliveryAddress={allCustomers.filter((customer) => {
                    if (customer?._id == selectedWorkorderData?.customer?._id) {
                      return [...customer?.deliveryAdresses];
                    }
                  })}
                  customerIdForNewWorkorder={state?.customerId}
                  exportInvoice={() => handleExportInvoice()}
                  offerType={offer}
                />
              </Tab>
              {offer && (
                <Tab
                  eventKey={"material"}
                  title={"Offer rows"}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  className="p-4"
                >
                  <MaterialTab exportInvoice={() => handleExportInvoice()} />
                </Tab> 
              )}
              {/* material */}
              {isEdit && (
                <Tab
                  eventKey={"material"}
                  title={offer ? "Offer rows" : t("material")}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  className="p-4"
                >
                  <MaterialTab exportInvoice={() => handleExportInvoice()} />
                </Tab> 
              )}
              {/* time report */}
              {isEdit && (
                <Tab
                  eventKey={"timereport"}
                  title={t("time_report")}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  className={`p-4 ${!isExportInvoiceVisible
                    ? "disabled-tab"
                    : ""
                    }`}
                  disabled={!isExportInvoiceVisible}
                >
                  <TimeReportTab
                    // createTimeReport={handleCreateTimeReport}
                    // timeReportsList={timeReportList}
                    activeTab={(d) => dispatch(currentTab(d))}
                    exportInvoice={() => handleExportInvoice()}
                  />
                </Tab>
              )}
              {/* Customer */}
              {isEdit && customerDetail && (
                <Tab
                  eventKey={"Customer"}
                  title={t("customer")}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  className="p-4 pt-4 "
                >
                  <AddEditCustomerComponent
                    // workOrderCustomerData={customerDetail}
                    workorderData={
                      selectedWorkorderData && selectedWorkorderData
                    }
                    setActiveTab={(d) => dispatch(currentTab(d))}
                    exportInvoice={() => handleExportInvoice()}
                  />
                  {/* <CustomerWorkOrder
                    setTimeReportList={setTimeReportList}
                    selectedWorkorderData={selectedWorkorderData}
                  /> */}
                </Tab>
              )}
              {/* Selfcontrol  */}
              {isEdit && (
                <Tab
                  eventKey={"selfControl"}
                  title={t("self_control")}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  className={`p-4 ${!isExportInvoiceVisible ? "disabled-tab"
                    : ""
                    }`}
                  disabled={!isExportInvoiceVisible}
                >
                  <SelfControlTab
                    selfControls={selfControls}
                    setSelfControls={setSelfControls}
                    selectedSelfControlsList={selectedSelfControlsList}
                    setSelectedSelfControlsList={setSelectedSelfControlsList}
                    selectedSelfControlsListPoints={
                      selectedSelfControlsListPoints
                    }
                    setSelectedSelfControlsListPoints={
                      setSelectedSelfControlsListPoints
                    }
                    pointCount={pointCount}
                    setPointCount={setPointCount}
                    exportInvoice={() => handleExportInvoice()}
                  />
                </Tab>
              )}
              {/* Summary */}
              {(isEdit || offer) && (
                <Tab
                  eventKey={"summary"}
                  title={t("summary")}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  className="p-4"
                >
                  <SummaryTab exportInvoice={() => handleExportInvoice()} />
                </Tab>
              )}
              {/* Document */}
              {(isEdit || offer) && (
                <Tab
                  eventKey={"document"}
                  title={t("document")}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  className="p-4"
                >
                  <DocumentTab exportInvoice={() => handleExportInvoice()} />
                </Tab>
              )}
              {/* Invoice */}
              {isEdit && (
                <Tab
                  eventKey={"Invoices"}
                  title={t("Invoices")}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  className={`p-4 pt-4 ${!isExportInvoiceVisible ? "disabled-tab"
                    : ""
                    }`}
                  disabled={!isExportInvoiceVisible}
                >
                  <InvoicesTab />
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditWorkorderComponent;
